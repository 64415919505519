import http from "../http-common";
const axios = require('axios');

class MemberDataService {
  getAll() {
    return http.get("/api/users");
  }

  get(id) {
    return http.get(`/api/members/${id}`);
  }

  // create(data) {
  //   return http.post("/api/members", data);
  // }

  // update(id, data) {
  //   return http.put(`/api/members/${id}`, data);
  // }

  updateUser(id, data) {
    return http.put(`/api/users/${id}`, data);
  }

  // delete(id) {
  //   return http.delete(`/api/members/${id}`);
  // }

  // deleteAll() {
  //   return http.delete(`/api/members`);
  // }

  // findByNameDate(data) {
  //   return http.post(`/api/members/findbyNameDate`, data);
  // }

  register(data) {
    return http.post("/api/users", data);
  }

  // updateRegister(id, data) {
  //   return http.put(`/api/users/${id}`, data);
  // }
  cancelMembership(id){
    return http.delete(`/api/users/cancelMembership/${id}`)
  }
  updatePW(data){
    return http.put(`/api/users/updatePW`, data);
  }
  userCheck(data) {
    return http.post(`/api/users/userCheck`, data);
  }

  emailLicenceCheck(data) {
    return http.post(`/api/users/emailLicenceCheck`, data);
  }

  keyCheck(data) {
    return http.post(`/api/users/keyCheck`, data);
  }

  validateLogin(data) {
    return http.post(`/api/login`, data);
  }

  loginCounter(data) {
    return http.post(`/api/users/loginCounter`, data);
  }

  emailSent(data) {
    return http.post('/api/users/emailSent', data);
  }

  findId(data) {
    return http.post('/api/users/findId', data);
  }

  findPassword(data) {
    return http.post('/api/users/findPassword', data);
  }

  // validateAdminLogin(data) {
  //   return http.post('/api/adminlogin', data);
  //   // return axios.post(`/api/adminlogin`, data).then(r=>{
  //   //   console.log(r);
  //   // }).catch(e=>{
  //   //   console.log(e);
  //   // });
  // }

  // memberLogin(data) {
  //   return http.post('/api/users/memberLoginPage', data)
  // }

  logoutAdmin() {
    return http.get("/api/logout");
  }
}

export default new MemberDataService();